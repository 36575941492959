import React, {Fragment, useState, useEffect} from "react";
import {RiCommunityLine, RiUser2Line, RiUserLine, RiUser3Line} from 'react-icons/ri';
import UserService from "../../services/UserService";
import {useAuth} from "../../hooks/useAuth";


const UserList = () => {
    const [users, setUsers] = useState([]);

    const { user } = useAuth();

    useEffect(() => {
        fetchUsers();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchUsers = async () => {
        try {
            const response = await UserService.getUsers();
            setUsers(response.data);
        } catch (err) {
            console.error("Error while getting user list:", err);
        }
    };

    const roleToAdmin = async (id) => {
        try {
            await UserService.roleToAdmin(id);
            await fetchUsers();
        } catch (err) {
            console.error("Error while setting role to admin:", err);
        }
    }

    const roleToMember = async (id) => {
        try {
            await UserService.roleToMember(id);
            await fetchUsers();
        } catch (err) {
            console.error("Error while setting role to member:", err);
        }
    }

    const roleToCustomer = async (id) => {
        try {
            await UserService.roleToCustomer(id);
            await fetchUsers();
        } catch (err) {
            console.error("Error while setting role to customer:", err);
        }
    }

    return (
        <Fragment>
            {user.role === 'Administrator' && (
                <div className='chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1'>
                    <div className='file-manager-content w-100 p-3 py-0'>
                        <div>
                            <div style={{padding: "24px 0"}}>
                                <div className="d-flex align-items-center mb-3">
                                    <h5 style={{textTransform: "capitalize"}} className="flex-grow-1 fs-16 mb-0" id="filetype-title">
                                        User List
                                    </h5>
                                </div>
                                <table className={'table align-middle table-nowrap mb-0'}>
                                    <thead className={'table-active'}>
                                    <tr>
                                        <th>E-Mail</th>
                                        <th>Rolle</th>
                                        <th>Rolle ändern zu</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.length > 0 && users.map(user => (
                                        <tr key={user._id}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 fs-17 me-2 filelist-icon text-success">
                                                        <RiCommunityLine />
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-grow-1 filelist-name">{user.email}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 fs-17 me-2 filelist-icon text-warning">
                                                        <RiUser3Line />
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-grow-1 filelist-name">{user.role}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex gap-3 justify-content-center">
                                                    <button type="button" className="btn btn-ghost-primary btn-icon btn-sm dcv-tooltip" onClick={() => roleToAdmin(user._id)}>
                                                        <RiUser2Line />
                                                        <span className="tooltiptext">Admin</span>
                                                    </button>
                                                    <button type="button" className="btn btn-ghost-primary btn-icon btn-sm dcv-tooltip" onClick={() => roleToMember(user._id)}>
                                                        <RiUserLine />
                                                        <span className="tooltiptext dcv-width120">Member</span>
                                                    </button>
                                                    <button type="button" className="btn btn-ghost-primary btn-icon btn-sm dcv-tooltip" onClick={() => roleToCustomer(user._id)}>
                                                        <RiUser3Line />
                                                        <span className="tooltiptext dcv-width120">Kunde</span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {user.role !== 'Administrator' && (
                <div className={'card'}>
                    <div className={'card-body'}>
                        <h5 className={'card-title'}>Du bist nicht berechtigt, diese Seite zu sehen.</h5>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default UserList;
