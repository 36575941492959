import React from 'react';
import logoSm from '../assets/img/logo-sm.png';
import logoDark from '../assets/img/logo-dark.png';
import logoLight from '../assets/img/logo.png';
import {Link} from 'react-router-dom';

const SidebarLogo = () => {
    return (
        <div className='navbar-brand-box'>
            <Link to='/dashboard' className='logo logo-dark'>
                <span className='logo-sm'>
                    <img src={logoSm} alt='' height='22' />
                </span>
                <span className='logo-lg'>
                    <img src={logoDark} alt='' height='17' />
                </span>
            </Link>
            <Link to='/dashboard' className='logo logo-light'>
                <span className='logo-sm'>
                    <img src={logoSm} alt='' height='22' />
                </span>
                <span className='logo-lg'>
                    <img src={logoLight} alt='' height='100' />
                </span>
            </Link>
            <button
                type='button'
                className='btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover'
                id='vertical-hover'
            >
                <i className='ri-record-circle-line'></i>
            </button>
        </div>
    );
};

export default SidebarLogo;
