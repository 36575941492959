import React from 'react';
import { useAuth } from '../hooks/useAuth';


const AdminProtected = ({ children }) => {
    const { user } = useAuth();

    if (user && user.role === "Administrator") {
        return children;
    }

};

export default AdminProtected;
