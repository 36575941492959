import axios from "axios";
import Service from "./Service";
import config from '../config/config.json';

class ImageUploadService  extends Service{
    static async imageUpload(formData) {
        try{
            await axios.post(`${config.serverUrl}/api/v1/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "X-Auth-Token": this.token,
                },
            });
        } catch (err){
            throw err;
        }
    };
}

export default ImageUploadService;