import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ApplicationService from "../services/ApplicationService";

const NavLink = ({ icon, to = '/', title = '', badge, watchers}) => {
    const [state, setState] = useState({
        applications: []
    });

    useEffect(() => {
        fetch()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetch = async () =>{
        try {
            const response = await ApplicationService.getApplications();
            setState({ ...state, applications: response.data });
        } catch (err) {
            console.log(err)
        }
    }

    const openApplicationsCount = state.applications.filter(
        application => application.status === 'open'
    ).length;

    if(watchers){
        watchers.register('navLinkApplicationList', fetch);
    }

    const click = () => {
        document.documentElement.dataset.sidebarSize = 'lg';
        document.documentElement.classList.remove('mobileOpen');
        document.querySelector('.hamburger-icon').classList.remove('open');
    };

    return (
        <li className='nav-item'>
            <Link
                className='nav-link menu-link'
                role='button'
                aria-expanded='false'
                to={to}
                onClick={click}
            >
                {icon}
                <span data-key='t-dashboards'>{title}</span>
                {badge && openApplicationsCount > 0 && (
                    <span className='badge badge-pill bg-danger' data-key='t-hot'>
                        {openApplicationsCount}
                    </span>
                )}
            </Link>
        </li>
    );
};

export default NavLink;
