import React from 'react';
import { useAuth } from '../hooks/useAuth';


const OrcasProtected = ({ children }) => {
    const { user } = useAuth();

    //only show if admin and user mail has @orcas.de
    if (user && user.role === "Administrator" && user.email.includes("@orcas.de")) {
        return children;
    }

};

export default OrcasProtected;
