import {useEffect, useState} from 'react';
import { useAuth } from '../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import UserService from "../services/UserService";
import ApplicationService from "../services/ApplicationService";

const MemberProtected = ({ children }) => {
    const { user } = useAuth();
    const location = useLocation();

    const [state, setState] = useState({
        user: {},
        userApplications: [],
        firstname: "",
        lastname: "",
        street: "",
        zip: "",
        city: "",
        lastLogin: "",
        profileImageUrl: "",
    });

    const fetch = async () =>{
        try {
            const userResponse = await UserService.getUser(user.id);

            setState({ ...state,
                user: userResponse.data.user,
                userApplications: userResponse.data.existingApplications,
                firstname: userResponse.data.user.firstName,
                lastname: userResponse.data.user.lastName,
                street: userResponse.data.user.street,
                zip: userResponse.data.user.zip,
                city: userResponse.data.user.city,
                lastLogin: userResponse.data.user.lastLogin,
                profileImageUrl: userResponse.data.profileImageUrl,
            });
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        fetch();
    }, [location]);

    if (state.user.role === "Administrator" || state.user.role === "Member") {
        return children;
    }

    return null;
};

export default MemberProtected;
