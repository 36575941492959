import axios from "axios";
import Service from "./Service";
import config from '../config/config.json';

class MemberFormService extends Service{
    static async postMemberForm(state) {
        try {
            await axios.post(`${config.serverUrl}/api/v1/memberform`, state, {
                headers: {
                    "X-Auth-Token": this.token,
                }
            });
        } catch (err) {
            throw err;
        }
    };
}

export default MemberFormService;