import React from 'react';
import { useAuth } from '../hooks/useAuth';


const OnlyNotMember = ({ children }) => {
    const { user } = useAuth();


    if (user && user.role !== "Administrator" && user.role !== "Member") {
        return children;
    }

};

export default OnlyNotMember;
