import React, {useEffect, useState, Fragment} from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';


const Protected = ({ children }) => {
    const {reloadLogin } = useAuth();
    const [loggedIn, setLoggedIn] = useState(null);

    const reLogin = async () => {
        const authToken = localStorage.getItem('authToken');
        setLoggedIn(await reloadLogin(authToken));
    }

    useEffect(() => {
        reLogin();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    if(loggedIn === false){
        const redirect = encodeURIComponent(window.location.pathname);
        return <Navigate to={`/login?redirect=${redirect}`} replace />;
    } else if(loggedIn === null){
        return <Fragment />
    }

    return children;
};

export default Protected;
