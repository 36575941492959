import React from 'react';
import {RiLogoutBoxLine} from "react-icons/ri";
import {Link} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
import {useTranslation} from "react-i18next";

const LogoutButton = () => {
    const t = useTranslation().t;
    const {signout } = useAuth();

    return (
        <Link className="btn header-item" to="/login" onClick={signout}>
            <RiLogoutBoxLine className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" />
        </Link>
    );
};

export default LogoutButton;
