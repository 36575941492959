import React, {Fragment, useState, useEffect} from "react";
import {Link} from 'react-router-dom';
import SimpleBar from "simplebar-react";
import { RiEdit2Line,  RiCommunityLine, RiUser3Line, RiCropLine } from 'react-icons/ri';
import ListSidebar from "../../parts/ListSidebar";
import Table from "../../parts/Table";
import moment from "moment";
import StatusBadge from "../../parts/StatusBadge";
import ApplicationService from "../../services/ApplicationService";
import {useTranslation} from "react-i18next";
import ApplicantDelete from "../../parts/ApplicantDelete";


const ApplicationList = () => {
    const t = useTranslation().t;
    const [state, setState] = useState({
        view: "open",
        applications: []
    });

    const changeView = (view) => {
        setState({ ...state, view: view });
    };

    const tableHeaders = [t('lbl_user_mail'), t('lbl_company'), t('lbl_person_contact'), t('lbl_status'), t('lbl_created_date'), t('lbl_approved_date'), t('lbl_valid_until'), t('lbl_rejected_until'), t('lbl_actions')];

    useEffect(() => {
        fetch()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetch = async () =>{
        try {
            const response = await ApplicationService.getApplications();
            setState({ ...state, applications: response.data });
        } catch (err) {
            console.error("Error while getting application list:", err)
        }
    }

    const countApplications = (status) => {
        return state.applications.filter(application => application.status === status).length;
    }

    return (
        <Fragment>
            <div className='d-lg-flex row'>
                <ListSidebar  title={t('txt_requests')}>
                    <SimpleBar>
                        <ul className="list-unstyled file-manager-menu">
                            <li>
                                <span className={`${state.view === "open" ? 'active' : ''}`} onClick={() => changeView("open")}>
                                    {t('lbl_open')} ({countApplications("open")})
                                </span>
                            </li>
                            <li>
                                <span className={`${state.view === "pending" ? 'active' : ''}`} onClick={() => changeView("pending")}>
                                    {t('lbl_pending')} ({countApplications("pending")})
                                </span>
                            </li>
                            <li>
                                <span className={`${state.view === "approved" ? 'active' : ''}`} onClick={() => changeView("approved")}>
                                    {t('lbl_approved')} ({countApplications("approved")})
                                </span>
                            </li>
                            <li>
                                <span className={`${state.view === "rejected" ? 'active' : ''}`} onClick={() => changeView("rejected")}>
                                    {t('lbl_rejected')} ({countApplications("rejected")})
                                </span>
                            </li>
                            <li>
                                <span className={`${state.view === "canceled" ? 'active' : ''}`} onClick={() => changeView("canceled")}>
                                    {t('lbl_canceled')} ({countApplications("canceled")})
                                </span>
                            </li>
                            <li>
                                <span className={`${state.view === "suspended" ? 'active' : ''}`} onClick={() => changeView("suspended")}>
                                    {t('lbl_suspended')} ({countApplications("suspended")})
                                </span>
                            </li>
                        </ul>
                    </SimpleBar>
                </ListSidebar>
                <div className='col-md-10'>
                    <div className={'card'}>
                        <div style={{padding: "24px"}} className={'card-body'}>
                            <div className="d-flex align-items-center mb-3">
                                <h5 style={{textTransform: "capitalize"}} className="flex-grow-1 fs-16 mb-0" id="filetype-title">{t(`lbl_${state.view}`)}</h5>
                            </div>
                            <Table headers={tableHeaders}>
                                {state.applications.filter(application => state.view === application.status).map(application => (
                                    <tr style={{display: `${state.view === application.status ? 'table-row' : 'none'}`}} key={`${application._id}`}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                {application.company && (
                                                    <div className="flex-shrink-0 fs-17 me-2 filelist-icon text-success">
                                                        <RiCommunityLine />
                                                    </div>
                                                )}
                                                {application.company === undefined && (
                                                    <div className="flex-shrink-0 fs-17 me-2 filelist-icon text-warning">
                                                        <RiUser3Line />
                                                    </div>
                                                )}

                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1 filelist-name">{application.user ? application.user.email : 'No User found'}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{application.company ? application.company.companyName : t('lbl_no_company')}</td>
                                        <td>{application.person.firstName} {application.person.lastName}</td>
                                        <td>
                                            <StatusBadge status={application.status} />
                                        </td>
                                        <td>{application.createdDate && moment(application.createdDate).format('DD.MM.YYYY')}</td>
                                        <td>{application.approvedDate && moment(application.approvedDate).format('DD.MM.YYYY')}</td>
                                        <td>{application.validUntil && moment(application.validUntil).format('DD.MM.YYYY')}</td>
                                        <td>{application.rejectedUntil && moment(application.rejectedUntil).format('DD.MM.YYYY')}</td>
                                        <td>
                                            <div className="d-flex gap-3 justify-content-center">
                                                <Link to={`/application/${application._id}`}>
                                                    <button type="button" className="btn btn-ghost-primary btn-icon btn-sm dcv-tooltip">
                                                        <RiEdit2Line />
                                                        <span className="tooltiptext">Bearbeiten</span>
                                                    </button>
                                                </Link>
                                                <ApplicantDelete id={application._id} fetch={fetch} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {state.applications.filter(application => application.status === "open").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "open" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_open_requests')}</td></tr>
                                )}
                                {state.applications.filter(application => application.status === "approved").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "approved" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_approved_requests')}</td></tr>
                                )}
                                {state.applications.filter(application => application.status === "rejected").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "rejected" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_rejected_requests')}</td></tr>
                                )}
                                {state.applications.filter(application => application.status === "pending").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "pending" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_pending_requests')}</td></tr>
                                )}
                                {state.applications.filter(application => application.status === "canceled").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "canceled" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_canceled_requests')}</td></tr>
                                )}
                                {state.applications.filter(application => application.status === "suspended").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "suspended" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_suspended_requests')}</td></tr>
                                )}

                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ApplicationList;
