import React, { useRef } from 'react';

const BurgerButton = () => {
    const click = () => {
        if (burgerRef.current.classList.contains('open')) {
            document.documentElement.dataset.sidebarSize = 'lg';
            burgerRef.current.classList.remove('open');
            document.documentElement.classList.remove('mobileOpen');
        } else {
            document.documentElement.dataset.sidebarSize = 'sm';
            burgerRef.current.classList.add('open');
            document.documentElement.classList.add('mobileOpen');
        }
    };

    const burgerRef = useRef();

    return (
        <button
            type='button'
            className='btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger'
            id='topnav-hamburger-icon'
        >
            <span className='hamburger-icon' id={"topnav-hamburger-icon-span"} onClick={click} ref={burgerRef}>
                <span></span>
                <span></span>
                <span></span>
            </span>
        </button>
    );
};

export default BurgerButton;
