import React, {Fragment, useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import {isEmpty} from "../../utils/validation";
import MemberFormService from "../../services/MemberFormService";
import {useTranslation} from "react-i18next";
import StatusBadge from "../../parts/StatusBadge";
import UserService from "../../services/UserService";
import {useAuth} from "../../hooks/useAuth";

const MemberForm = () => {
    const t = useTranslation().t;
    const { user } = useAuth();
    const navigate = useNavigate();
    const [applications, setApplications] = useState([]);
    const [state, setState] = useState({
        options: "person",
        companyName: '',
        companyStreet: '',
        companyCity: '',
        companyZip: '',
        companyDate: '',
        companyReg: '',
        companyPosition: '',
        companyFirstName: '',
        companyLastName: '',
        companyEmail: '',
        companyPhone: '',
        personFirstName: '',
        personLastName: '',
        personStreet: '',
        personCity: '',
        personZip: '',
        personEmail: '',
        personPhone: '',
        personDate: '',
        extraInfo: '',
    });
    const [errors, setErrors] = useState({});
    const [invalid, setInvalid] = useState(false);

    const checkChange = (e) => {
        setState({ ...state, options: e.target.value });
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            await MemberFormService.postMemberForm(state);
            navigate('/profile', { replace: true });
        } catch (err) {
            setErrors(err.response.data.errors);
            setInvalid(true);
        }
    };

    const change = (e) => {
        if (isEmpty(e.target.value)) {
            setErrors({ ...errors, [e.target.name]: `${e.target.name} is required` });
        } else {
            setErrors({ ...errors, [e.target.name]: false });
        }
        setState({ ...state, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetch();
    }, [user.id]);

    const fetch = async () =>{
        try {
            const response = await UserService.getUser(user.id);
            setApplications(response.data.existingApplications);
        } catch (err) {
            setErrors(err.response.data.errors);
            console.error(err)
        }
    }

    return (
        <Fragment>
            <div className='row'>
                <div className='col-12'>
                    <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <h4 className='mb-sm-0'>{t('lbl_member_form')}</h4>

                        <div className='page-title-right'></div>
                    </div>
                </div>
            </div>
            {applications.length > 0 && applications.map((application, index) => (
                <div key={index}>
                    {application.status === "open" && (
                        <div className='card'>
                            <div className='card-body'>
                                {t('txt_current_request_status')} <StatusBadge status={application.status} />. {t('txt_email_if_changes')}
                            </div>
                        </div>
                    )}
                </div>
            ))}
            <form className="row g-3" onSubmit={submit}>
                <div className='card'>
                    <div className='card-header align-items-center d-flex'>
                        <h4 className="card-title mb-0 flex-grow-1">{t('lbl_choose_register')}</h4>
                    </div>
                    <div className='card-body'>
                        <div>
                            <div className="hstack gap-2 flex-wrap">
                                <input type="radio" className="btn-check" name="options" id="option2" value="person" checked={state.options === "person"} onChange={(e) => checkChange(e)}/>
                                <label className={`btn ${state.options === "person" ? 'btn-primary' : 'btn-light'}`} htmlFor="option2">{t('btn_person')}</label>

                                <input type="radio" className="btn-check" name="options" id="option1" value="company" checked={state.options === "company"} onChange={(e) => checkChange(e)}/>
                                <label className={`btn ${state.options === "company" ? 'btn-primary' : 'btn-light'}`} htmlFor="option1">{t('btn_company')}</label>
                            </div>
                        </div>
                        {invalid &&
                        <span className='text-danger'>{t('lbl_invalid')}</span>
                        }
                        {errors.existApplication &&
                        <div className="text-danger">{t('lbl_application_exist')}</div>
                        }
                    </div>
                </div>
                {state.options === "company" && (
                    <div className='card'>
                        <div className='card-header align-items-center d-flex'>
                            <h4 className="card-title mb-0 flex-grow-1">{t('lbl_company')}</h4>
                        </div>
                        <div className='card-body'>
                            <div className='row g-3'>
                                <div className="col-md-12">
                                    <label htmlFor="companyName" className="form-label">{t('lbl_company_name')}*</label>
                                    <input type="text" className={`form-control ${errors.companyName && 'is-invalid'}`} name='companyName' id="companyName" onChange={change} value={state.companyName} placeholder={t('lbl_company_name_input')}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="companyStreet" className="form-label">{t('lbl_street')}*</label>
                                    <input type="text" className={`form-control ${errors.companyStreet && 'is-invalid'}`} name='companyStreet' id="companyStreet" onChange={change} value={state.companyStreet} placeholder={t('lbl_street_input')}/>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="companyCity" className="form-label">{t('lbl_city')}*</label>
                                    <input type="text" className={`form-control ${errors.companyCity && 'is-invalid'}`} name='companyCity' id="companyCity" onChange={change} value={state.companyCity} placeholder={t('lbl_city_input')}/>
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="companyZip" className="form-label">{t('lbl_zip')}*</label>
                                    <input type="text" className={`form-control ${errors.companyZip && 'is-invalid'}`} name='companyZip' id="companyZip" onChange={change} value={state.companyZip} placeholder={t('lbl_zip_input')}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="companyDate" className="form-label">{t('lbl_date_of_foundation')}</label>
                                    <input type="date" className="form-control" name='companyDate' id="companyDate" onChange={change} value={state.companyDate} readOnly={true} onFocus={(e) => { e.currentTarget.removeAttribute('readonly'); }}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="companyReg" className="form-label">{t('lbl_registration_nr')}</label>
                                    <input type="text" className="form-control" name='companyReg' id="companyReg" onChange={change} value={state.companyReg} placeholder={t('lbl_registration_nr_input')}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {state.options === "company" && (
                    <div className='card'>
                        <div className='card-header align-items-center d-flex'>
                            <h4 className="card-title mb-0 flex-grow-1">{t('lbl_company_contact_person')}</h4>
                        </div>
                        <div className='card-body'>
                            <div className='row g-3'>
                                <div className="col-md-12">
                                    <label htmlFor="companyPosition" className="form-label">{t('lbl_position')}</label>
                                    <input type="text" className="form-control" name='companyPosition' id="companyPosition" onChange={change} value={state.companyPosition} placeholder={t('lbl_position_input')}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="companyFirstName" className="form-label">{t('lbl_contact_firstname')}*</label>
                                    <input type="text" className={`form-control ${errors.companyFirstName && 'is-invalid'}`} name='companyFirstName' id="companyFirstName" onChange={change} value={state.companyFirstName} placeholder={t('lbl_contact_firstname_input')}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="companyLastName" className="form-label">{t('lbl_contact_lastname')}*</label>
                                    <input type="text" className={`form-control ${errors.companyLastName && 'is-invalid'}`} name='companyLastName' id="companyLastName" onChange={change} value={state.companyLastName} placeholder={t('lbl_contact_lastname_input')}/>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="companyEmail" className="form-label">{t('lbl_email')}*</label>
                                    <input type="email" className={`form-control ${errors.companyEmail && 'is-invalid'}`} name='companyEmail' id="companyEmail" onChange={change} value={state.companyEmail} placeholder={t('lbl_email_input')}/>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="companyPhone" className="form-label">{t('lbl_phone')}*</label>
                                    <input type="tel" className={`form-control ${errors.companyPhone && 'is-invalid'}`} name='companyPhone' id="companyPhone" onChange={change} value={state.companyPhone} placeholder={t('lbl_phone_input')}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}



                {state.options === "person" && (
                    <div className='card'>
                        <div className='card-header align-items-center d-flex'>
                            <h4 className="card-title mb-0 flex-grow-1">{t('lbl_person')}</h4>
                        </div>
                        <div className='card-body'>
                            <div className='row g-3'>
                                <div className="col-md-6">
                                    <label htmlFor="personFirstName" className="form-label">{t('lbl_firstname')}*</label>
                                    <input type="text" className={`form-control ${errors.personFirstName && 'is-invalid'}`} name='personFirstName' id="personFirstName" onChange={change} value={state.personFirstName} placeholder={t('lbl_firstname_input')}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="personLastName" className="form-label">{t('lbl_lastname')}*</label>
                                    <input type="text" className={`form-control ${errors.personLastName && 'is-invalid'}`} name='personLastName' id="personLastName" onChange={change} value={state.personLastName} placeholder={t('lbl_lastname_input')}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="personStreet" className="form-label">{t('lbl_street')}*</label>
                                    <input type="text" className={`form-control ${errors.personStreet && 'is-invalid'}`} name='personStreet' id="personStreet" onChange={change} value={state.personStreet} placeholder={t('lbl_street_input')}/>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="personCity" className="form-label">{t('lbl_city')}*</label>
                                    <input type="text" className={`form-control ${errors.personCity && 'is-invalid'}`} name='personCity' id="personCity" onChange={change} value={state.personCity} placeholder={t('lbl_city_input')}/>
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="personZip" className="form-label">{t('lbl_zip')}*</label>
                                    <input type="text" className={`form-control ${errors.personZip && 'is-invalid'}`} name='personZip' id="personZip" onChange={change} value={state.personZip} placeholder={t('lbl_zip_input')}/>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="personEmail" className="form-label">{t('lbl_email')}*</label>
                                    <input type="email" className={`form-control ${errors.personEmail && 'is-invalid'}`} name='personEmail' id="personEmail" onChange={change} value={state.personEmail} placeholder={t('lbl_email_input')}/>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="personPhone" className="form-label">{t('lbl_phone')}*</label>
                                    <input type="tel" className={`form-control ${errors.personPhone && 'is-invalid'}`} name='personPhone' id="personPhone" onChange={change} value={state.personPhone} placeholder={t('lbl_phone_input')}/>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="personDate" className="form-label">{t('lbl_birth_date')}*</label>
                                    <input type="date" className={`form-control ${errors.personDate && 'is-invalid'}`} name='personDate' id="personDate" onChange={change} value={state.personDate} onFocus={(e) => { e.currentTarget.removeAttribute('readonly'); }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className='card'>
                    <div className='card-header align-items-center d-flex'>
                        <h4 className="card-title mb-0 flex-grow-1">{t('lbl_extra_info')}</h4>
                    </div>
                    <div className='card-body'>
                        <div className='row g-3'>
                            <div className="col-md-12">
                                <textarea className="form-control" name='extraInfo' id="extraInfo" onChange={change} value={state.extraInfo} placeholder={t('lbl_extraInfo_textarea')}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card'>
                    <div className='card-header align-items-center d-flex'>
                        <h4 className="card-title mb-0 flex-grow-1">{t('lbl_apply_to_become_member')}</h4>
                    </div>
                    <div className='card-body'>
                        <div className='row g-3'>
                            <div className="col-md-12">
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="checkbox" id="formCheck1" required={true}/>
                                        <label className="form-check-label" htmlFor="formCheck1">
                                            {t('lbl_check_agb')}
                                        </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="checkbox" id="formCheck2" required={true}/>
                                    <label className="form-check-label" htmlFor="formCheck2">
                                        {t('lbl_check_dsgvo')}
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="text-end">
                                    <button type="submit" className="btn btn-primary">{t('btn_be_member')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default MemberForm;
