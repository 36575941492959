import React from 'react';

const Table = ({ headers, children }) => {
    const numHeaders = headers.length;

    return (
        <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
                <thead className="table-active">
                <tr>
                    {headers.map((header, index) => (
                        <th key={index} scope="col" className={index === numHeaders - 1 ? "text-center" : ""}>
                            {header}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody id="file-list">
                {children}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
