import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom'
import {useAuth} from "../hooks/useAuth";
import UserService from "../services/UserService";
import $ from 'jquery';
import { RiAccountCircleLine, RiLogoutBoxLine } from 'react-icons/ri';
import {useTranslation} from "react-i18next";
import config from '../config/config.json';

const ProfileDropdown = () => {
    const t = useTranslation().t;
    const { user, signout } = useAuth();
    const [state, setState] = useState({
        user: null,
        firstname: '',
        lastname: '',
        profileImageUrl: null,
    });

    /**
     * Services
     */

    useEffect(() => {
        fetch()
    }, [user.id]);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Functions
     */
    const fetch = async () =>{
        try {
            const response = await UserService.getUser(user.id);
            const data = {
                user: response.data.user,
                profileImageUrl: response.data.profileImageUrl,
            }
            setState({...data});
        } catch (err) {
            console.error(err)
        }
    }

    const click = () => {
        if (dropdownRef.current.classList.contains('show')) {
            dropdownRef.current.classList.remove('show');
            $(dropdownContentRef.current).slideUp();
        } else {
            dropdownRef.current.classList.add('show');
            $(dropdownContentRef.current).slideDown();
        }
    };

    const dropdownRef = useRef();
    const dropdownContentRef = useRef();

    return (
        <div className="dropdown ms-sm-3 header-item topbar-user">
            <div className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" ref={dropdownRef} onClick={click}
                    aria-haspopup="true" aria-expanded="true">
                        <span className="d-flex align-items-center">
                            {state.profileImageUrl ? (
                                <img style={{objectFit: "cover"}} src={`${config.serverUrl}${state.profileImageUrl}`} className='rounded-circle header-profile-user' alt="Selected"/>
                            ) : (
                                <span style={{backgroundColor: "#405189",display: "flex", justifyContent: "center", alignItems: "center"}} className='rounded-circle header-profile-user' />
                            )}

                            <span className="text-start ms-xl-2">
                                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{user.email}</span>
                                <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">{t(`lbl_${user.role.toLowerCase()}`)}</span>
                            </span>
                        </span>
            </div>
            <div className="dropdown-menu dropdown-menu-end" ref={dropdownContentRef}
                 style={{position: "absolute", inset:" 0px 0px auto auto", margin: "0px", transform: "translate(0px, 65px)"}}
                 data-popper-placement="bottom-end">
                <Link className="dropdown-item" to="/profile" onClick={click}>
                    <RiAccountCircleLine className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" />
                    <span className="align-middle">{t('lbl_profile')}</span>
                </Link>
            </div>
        </div>
    );
};

export default ProfileDropdown;