import React, { useState, useEffect } from 'react';
import { AiFillHeart } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { isEmpty } from '../utils/validation';

const PasswordResetPage = () => {
    const { confirmPasswordReset } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        password: '',
        passwordconfirm: ''
    });
    const [errors, setErrors] = useState({});
    const [invalid, setInvalid] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState(false);
    const validate = () => {
        let hasErrors = false;
        let errors = {};
        for (const key in state) {
            if (isEmpty(state[key])) {
                errors[key] = `${key} is required`;
                hasErrors = true;
            }
        }

        if (hasErrors) {
            setErrors(errors);
        }
        return !hasErrors;
    };

    useEffect(() => {
        const token = new URLSearchParams(location.search).get('token');
        if (!token) {
            setErrors('Invalid Token.');
        }
    }, [location.search]);

    const submit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                const token = new URLSearchParams(location.search).get('token');
                await confirmPasswordReset(token, state);
                navigate('/login', { replace: true, state: { passwordChanged: true }  });
            } catch (err) {
                setPasswordConfirm(true);
                setInvalid(true);
            }
        }
    };

    const change = (e) => {
        if (isEmpty(e.target.value)) {
            setErrors({ ...errors, [e.target.name]: `${e.target.name} is required` });
        } else {
            setErrors({ ...errors, [e.target.name]: false });
        }
        setState({ ...state, [e.target.name]: e.target.value });
    };

    return (
        <div className='auth-page-wrapper pt-5'>
            <div className='auth-one-bg-position auth-one-bg' id='auth-particles'>
                <div className='bg-overlay'></div>

                <div className='shape'>
                    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1440 120'>
                        <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z'></path>
                    </svg>
                </div>
            </div>
            <div className='auth-page-content'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8 col-lg-6 col-xl-5'>
                            <div className='card mt-4'>
                                <div className='card-body p-4'>
                                    <div className='text-center mt-2'>
                                        <h5 className='text-primary'>Welcome Back!</h5>
                                        <p className='text-muted'>Change your Password now!</p>
                                    </div>
                                    {invalid && <span className='text-danger'>Invalid Credentials!</span>}
                                    <div className='p-2 mt-4'>
                                        <form onSubmit={submit}>
                                           <div className='mb-3'>
                                                <label className='form-label' htmlFor='password'>
                                                    Neues Password
                                                </label>
                                                <div className='position-relative auth-pass-inputgroup mb-3'>
                                                    <input
                                                        type='password'
                                                        className={`form-control pe-5 password-input ${
                                                            errors.password && 'is-invalid'
                                                        }`}
                                                        placeholder='Enter password'
                                                        name='password'
                                                        onChange={change}
                                                        value={state.password}
                                                    />
                                                    <div className='invalid-feedback'>{errors.password}</div>
                                                </div>
                                            </div>
                                            <div className='mb-3'>
                                                <label className='form-label' htmlFor='passwordconfirm'>
                                                    Enter password again
                                                </label>
                                                <div className='position-relative auth-pass-inputgroup mb-3'>
                                                    <input
                                                        type='password'
                                                        className={`form-control pe-5 password-input ${
                                                            errors.passwordconfirm && 'is-invalid'
                                                        }`}
                                                        placeholder='Enter password again'
                                                        name='passwordconfirm'
                                                        onChange={change}
                                                        value={state.passwordconfirm}
                                                    />
                                                    <div className='invalid-feedback'>{errors.passwordconfirm}</div>
                                                    {passwordConfirm && <div className='text-danger'>Passwords are not equal</div>}
                                                </div>
                                            </div>
                                            <div className='mt-4'>
                                                <button className='btn btn-success w-100' type='submit'>
                                                    Change Password
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4 text-center'>
                                <p className='mb-0'>
                                    You want to Login ?
                                    <a
                                        href='/login'
                                        className='fw-semibold text-primary text-decoration-underline'
                                    >
                                       Login
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className='footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='text-center'>
                                <p className='mb-0 text-muted'>
                                    ©2024 DCV. Crafted with <AiFillHeart className='text-danger' /> by orcas
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default PasswordResetPage;
