import React from 'react';

const Version = () => {
    return (
        <div className='version-container customizer-setting bg-secondary-subtle p-2 text-secondary' style={{bottom: "20px"}}>
            Version: 0.4.1
        </div>
    );
};

export default Version;
