import React, { Fragment } from "react";

const Sample = () => {
    return (
        <Fragment>
            <div class='row'>
                <div class='col-12'>
                    <div class='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <h4 class='mb-sm-0'>Sample</h4>

                        <div class='page-title-right'></div>
                    </div>
                </div>
            </div>
            <div>Sample</div>
        </Fragment>
    );
};

export default Sample;
