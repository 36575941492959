import React from 'react';
import TopBarModules from '../modules/TopBarModules';
import BurgerButton from './BurgerButton';
import TopBarLogo from './TopBarLogo';

const TopBar = () => {
    return (
        <header id='page-topbar'>
            <div className='layout-width'>
                <div className='navbar-header'>
                    <div className='d-flex'>
                        <TopBarLogo />
                        <BurgerButton />
                    </div>
                    <TopBarModules />
                </div>
            </div>
        </header>
    );
};

export default TopBar;
