import React from 'react';
import {useTranslation} from "react-i18next";

const StatusBadge = ({ status }) => {
    const t = useTranslation().t;
    let badgeClassName, badgeText;

    switch (status) {
        case 'pending':
            badgeClassName = 'badge bg-warning-subtle text-warning';
            badgeText = t('lbl_pending');
            break;
        case 'open':
            badgeClassName = 'badge bg-secondary-subtle text-secondary';
            badgeText = t('lbl_open');
            break;
        case 'approved':
            badgeClassName = 'badge bg-success-subtle text-success';
            badgeText = t('lbl_approved');
            break;
        case 'rejected':
            badgeClassName = 'badge bg-danger-subtle text-danger';
            badgeText = t('lbl_rejected');
            break;
        case 'canceled':
            badgeClassName = 'badge bg-danger-subtle text-danger';
            badgeText = t('lbl_canceled');
            break;
        case 'suspended':
            badgeClassName = 'badge bg-warning-subtle text-warning';
            badgeText = t('lbl_suspended');
            break;
        default:
            badgeClassName = '';
            badgeText = '';
    }

    return (
        <span className={badgeClassName}>{badgeText}</span>
    );
};

export default StatusBadge;