import React, {createContext, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

const autoSaveContext = createContext();

export function ProvideAutoSave({ children }) {
    const formAutoSave = useFormAutoSave();
    return <autoSaveContext.Provider value={formAutoSave}>{children}</autoSaveContext.Provider>;
}

export const useAutoSave = () => {
    return useContext(autoSaveContext);
};


function useFormAutoSave() {
    let current = null;
    //const [current, setCurrent] = useState(null);
    const [callbacks, setCallbacks] = useState([]);


    /**
     * Services
     */
    const location = useLocation();

    useEffect(()  => {
        submitForms();

    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    const submitForms = () => {
        if(current !== location.key) {
            for(let cb of callbacks) {
                cb();
            }

            setCallbacks([]);

            current = location.key;
        }
    }

    const register = (submit) => {
        setCallbacks([...callbacks, submit]);
    }

    return {
        register
    }
}