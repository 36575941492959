import React, {Fragment, useState, useEffect} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import {isEmpty} from "../../utils/validation";
import StatusBadge from "../../parts/StatusBadge";
import ApplicationService from "../../services/ApplicationService";
import {useTranslation} from "react-i18next";


const Application = ({watchers, ...props}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const t = useTranslation().t;
    const navigate = useNavigate();
    const { id } = useParams();
    const [state, setState] = useState({
        application: null,
        newStatus: '',
        createdDate: '',
        currentStatus: '',
        approvedDate: '',
        validUntil: '',
        rejectedUntil: '',
        mailSend: 'yes',
        notice: '',
        id: id,
    });
    const [errors, setErrors] = useState({});
    useEffect(() => {
        fetch()
    }, [id]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetch = async () =>{
        try {
            const response = await ApplicationService.getApplication(id);
            setState({
                ...state,
                application: response.data,
                createdDate: response.data.createdDate ? moment(response.data.createdDate).format('YYYY-MM-DD') : '',
                currentStatus: response.data.status,
                newStatus: response.data.status,
                approvedDate: response.data.approvedDate ? moment(response.data.approvedDate).format('YYYY-MM-DD') : '',
                validUntil: response.data.validUntil ? moment(response.data.validUntil).format('YYYY-MM-DD') : '',
                rejectedUntil: response.data.rejectedUntil ? moment(response.data.rejectedUntil).format('YYYY-MM-DD') : '',
                notice: response.data.notice,
            });
        } catch (err) {
            setErrors(err.response.data.errors);
            console.error(err)
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        try {
            await ApplicationService.putApplication(state, id);
            if(watchers){
                watchers.notify('navLinkApplicationList');
            }
            navigate('/application-list', { replace: true });
        } catch (err) {
            setErrors(err.response.data.errors);
        }
    };

    const change = (e) => {
        if (isEmpty(e.target.value)) {
            setErrors({ ...errors, [e.target.name]: `${e.target.name} is required` });
        } else {
            setErrors({ ...errors, [e.target.name]: false });
        }
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleDoubleChange = (e) => {
        if(e.target.value === 'approved'){
            const today = moment(new Date()).format('YYYY-MM-DD');
            const nextYear = moment(new Date()).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD');

            setState({
                ...state,
                approvedDate: today,
                validUntil: nextYear,
                rejectedUntil: '',
                [e.target.name]: e.target.value,
                mailSend: 'yes'
            });
        }

        else if(e.target.value === 'pending'){
            setState({
                ...state,
                approvedDate: '',
                validUntil: '',
                rejectedUntil: '',
                [e.target.name]: e.target.value,
                mailSend: 'yes'
            });
        }

        else if(e.target.value === 'cancelled'){
            setState({
                ...state,
                rejectedUntil: '',
                [e.target.name]: e.target.value,
                mailSend: 'yes'
            });
        }

        else if(e.target.value === 'suspended'){
            setState({
                ...state,
                rejectedUntil: '',
                [e.target.name]: e.target.value,
                mailSend: 'yes'
            });
        }

        else if(e.target.value === 'open'){
            setState({
                ...state,
                approvedDate: '',
                validUntil: '',
                rejectedUntil: '',
                [e.target.name]: e.target.value,
                mailSend: 'yes'
            });
        }

        else if(e.target.value === 'rejected'){
            const nextYear = moment(new Date()).add(1, 'year').format('YYYY-MM-DD');

            setState({
                ...state,
                approvedDate: '',
                validUntil: '',
                rejectedUntil: nextYear,
                [e.target.name]: e.target.value,
                mailSend: 'no'
            });
        }
        else{
            setState({
                ...state,
                [e.target.name]: e.target.value,
                mailSend: 'yes'
            });
        }
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Fragment>
            <div className='row'>
                <div className='col-12'>
                    <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <h4 className='mb-sm-0'>{t('lbl_application_details')}</h4>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-xxl-3'>
                    <div className='card'>
                        <div className='card-body'>
                            <h6 className="card-title mb-3 flex-grow-1 text-start">{t('lbl_user')}</h6>
                            <div className="table-card">
                                <table className="table mb-0">
                                    <tbody>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_email')}</td>
                                            <td>{state.application && state.application.user.email}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <form onSubmit={submit}>
                            <div className='card-body'>
                                <h6 className="card-title mb-4 flex-grow-1 text-start">{t('lbl_application_information')}</h6>
                                <div className="table-card">
                                    <table className="table mb-4">
                                        <tbody>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_current_status')}</td>
                                            <td>
                                                <StatusBadge status={state.application && state.application.status} />
                                                <input type="hidden" name="currentStatus" id="currentStatus" value={`${state.application && state.application.status}`} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_new_status')}</td>
                                            <td>
                                                <select onChange={handleDoubleChange} value={state.newStatus} className="form-control" name="newStatus" id="newStatus">
                                                    <option value="open">{t('lbl_open')}</option>
                                                    <option value="pending">{t('lbl_pending')}</option>
                                                    <option value="approved">{t('lbl_approved')}</option>
                                                    <option value="rejected">{t('lbl_rejected')}</option>
                                                    {state.application && state.application.status === 'approved' && (
                                                        <option value="canceled">{t('lbl_canceled')}</option>
                                                    )}
                                                    {state.application && state.application.status === 'approved' && (
                                                        <option value="suspended">{t('lbl_suspended')}</option>
                                                    )}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_created_date')}</td>
                                            <td>
                                                <input
                                                    type="date"
                                                    id="createdDate"
                                                    name='createdDate'
                                                    onChange={change}
                                                    value={state.createdDate}
                                                    className="form-control"
                                                    placeholder="Created Date"
                                                    required=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_approved_date')}</td>
                                            <td>
                                                <input
                                                    type="date"
                                                    id="approvedDate"
                                                    name='approvedDate'
                                                    onChange={change}
                                                    value={state.approvedDate}
                                                    className="form-control"
                                                    placeholder="approved Date"
                                                    required=""
                                                />
                                                {errors.notApproved &&
                                                    <span className="text-danger">{t('lbl_error_application_not_approved')}</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_valid_until')}</td>
                                            <td>
                                                <input
                                                    type="date"
                                                    id="validUntil"
                                                    name='validUntil'
                                                    onChange={change}
                                                    value={state.validUntil}
                                                    className="form-control"
                                                    placeholder="Valid Until"
                                                    required=""
                                                />
                                                {errors.notApproved &&
                                                <span className="text-danger">{t('lbl_error_application_not_approved')}</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_rejected_until')}</td>
                                            <td>
                                                <input
                                                    type="date"
                                                    id="rejectedUntil"
                                                    name='rejectedUntil'
                                                    onChange={change}
                                                    value={state.rejectedUntil}
                                                    className="form-control"
                                                    placeholder="Reject Until"
                                                    required=""
                                                />
                                                {errors.notRejected &&
                                                <span className="text-danger">{t('lbl_error_application_not_rejected')}</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_send_mail')}</td>
                                            <td>
                                                <select onChange={change} value={state.mailSend} className="form-control" name="mailSend" id="mailSend">
                                                    <option value="yes">{t('lbl_yes')}</option>
                                                    <option value="no">{t('lbl_no')}</option>
                                                </select>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12">
                                    <div className="text-end">
                                        <span className="btn btn-primary" onClick={openModal}>{t('btn_save')}</span>
                                        <div
                                            className={`dcv-modal modal fade ${isModalOpen ? 'show' : ''}`}
                                            tabIndex='-1'
                                            style={{ display: isModalOpen ? 'block' : 'none' }}
                                            aria-hidden={!isModalOpen}
                                        >
                                            <div className='modal-dialog'>
                                                <div className='modal-content'>
                                                    <div className='modal-header'>
                                                        <h5 className='modal-title' id='myModalLabel'>
                                                            Antrag speichern
                                                        </h5>
                                                        <button type='button' className='btn-close' aria-label='Close' onClick={closeModal} />
                                                    </div>
                                                    <div className='modal-body'>
                                                        <div className={'text-muted text-center'}>
                                                            Bist du sicher, dass du den Antrag speichern möchtest?
                                                        </div>
                                                    </div>
                                                    <div className='modal-footer'>
                                                        <span  className='btn btn-light' onClick={closeModal}>
                                                            Nein, abbrechen.
                                                        </span>
                                                        <span className='btn btn-primary' onClick={submit}>
                                                            Ja ich bin mir sicher.
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='col-xxl-9'>
                    {state.application && state.application.company && (
                        <div className='card'>
                            <div className='card-body'>
                                <h6 className="card-title mb-4 flex-grow-1 text-start">{t('lbl_company_information')}</h6>
                                <div className="table-card">
                                    <table className="table mb-0">
                                        <tbody>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_name')}</td>
                                            <td>{state.application.company.companyName}</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_address')}</td>
                                            <td>{state.application.company.companyStreet}, {state.application.company.companyZip} {state.application.company.companyCity}</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_date_of_foundation')}</td>
                                            <td>{moment(state.application.company.companyDate).format('YYYY-MM-DD')}</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-medium">{t('lbl_registration_nr')}</td>
                                            <td>{state.application.company.companyReg}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    {state.application && state.application.company && (
                        <div className='card'>
                            <div className='card-body'>
                                <h6 className="card-title mb-4 flex-grow-1 text-start">{t('lbl_contact_person_information')}</h6>
                                <div className="table-card">
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td className="fw-medium">{t('lbl_position')}</td>
                                                <td>{state.application.company.position}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-medium">{t('lbl_name')} ({t('lbl_lastname')}, {t('lbl_firstname')})</td>
                                                <td>{state.application.person.lastName}, {state.application.person.firstName}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-medium">{t('lbl_email')}</td>
                                                <td>{state.application.person.email}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-medium">{t('lbl_phone')}</td>
                                                <td>{state.application.person.phone}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}

                    {state.application && !state.application.company && (
                        <div className='card'>
                            <div className='card-body'>
                                <h6 className="card-title mb-4 flex-grow-1 text-start">{t('lbl_contact_person_information')}</h6>
                                <div className="table-card">
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td className="fw-medium">{t('lbl_name')} ({t('lbl_lastname')}, {t('lbl_firstname')})</td>
                                                <td>{state.application.person.lastName}, {state.application.person.firstName}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-medium">{t('lbl_address')}</td>
                                                <td>{state.application.person.street}, {state.application.person.zip} {state.application.person.city}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-medium">{t('lbl_email')}</td>
                                                <td>{state.application.person.email}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-medium">{t('lbl_phone')}</td>
                                                <td>{state.application.person.phone}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-medium">{t('lbl_birth_date')}</td>
                                                <td>{moment(state.application.person.dateOfBirth).format('DD.MM.YYYY')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    {state.application && state.application.extraInfo && (
                        <div className='card'>
                            <div className='card-body'>
                                <h6 className="card-title mb-4 flex-grow-1 text-start">{t('lbl_extra_info')}</h6>
                                <div className="table-card">
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td>{state.application.extraInfo}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='card'>
                        <div className='card-body'>
                            <h6 className="card-title mb-4 flex-grow-1 text-start">Notiz</h6>
                            <textarea onChange={change} name='notice' value={state.notice} className='form-control'>

                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Application;
