import axios from "axios";
import Service from "./Service";
import config from '../config/config.json';

class ApplicationService extends Service{
    static async putApplication(state, id) {
        try {
            await axios.put(`${config.serverUrl}/api/v1/application/${id}`, state, {
                headers: {
                    "X-Auth-Token": this.token,
                }
            });
        } catch (err) {
            throw err;
        }
    };

    static async getApplication(id) {
        try {
            return axios.get(`${config.serverUrl}/api/v1/application/${id}`, {
                headers: {
                    "X-Auth-Token": this.token,
                }
            });
        } catch (err) {
            throw err;
        }
    };

    static async deleteApplication(id) {
        try {
            return axios.delete(`${config.serverUrl}/api/v1/application/${id}`, {
                headers: {
                    "X-Auth-Token": this.token,
                }
            });
        } catch (err) {
            throw err;
        }
    };

    static async getApplications() {
        try {
            return axios.get(`${config.serverUrl}/api/v1/application/`, {
                headers: {
                    "X-Auth-Token": this.token,
                }
            });
        } catch (err) {
            throw err;
        }
    };
}

export default ApplicationService;