import React, {Fragment, useState} from 'react';
import {RiCloseLine} from "react-icons/ri";
import ApplicationService from "../services/ApplicationService";

const ApplicantDelete = ({id, fetch}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const deleteApplication = async () => {
        try {
            await ApplicationService.deleteApplication(id);
            setIsModalOpen(false);
            fetch();

        } catch (err) {
            console.error("Error while deleting application:", err)
        }
    }

    return (
        <Fragment>
            <button type="button" className="btn btn-ghost-danger btn-icon btn-sm dcv-tooltip" onClick={openModal}>
                <RiCloseLine />
                <span className="tooltiptext">Löschen</span>
            </button>
            <div
                className={`dcv-modal modal fade ${isModalOpen ? 'show' : ''}`}
                tabIndex='-1'
                style={{ display: isModalOpen ? 'block' : 'none' }}
                aria-hidden={!isModalOpen}
            >
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='myModalLabel'>
                                Löschen
                            </h5>
                            <button type='button' className='btn-close' aria-label='Close' onClick={closeModal} />
                        </div>
                        <div className='modal-body'>
                            <div className={'text-muted text-center'}>
                                Bist du sicher, dass du das löschen möchtest?
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <span  className='btn btn-light' onClick={closeModal}>
                                Nein, abbrechen.
                            </span>
                            <span className='btn btn-primary' onClick={() => deleteApplication()}>
                                Ja ich bin mir sicher.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ApplicantDelete;
