import React, {Fragment, useState, useEffect} from "react";
import {Link} from 'react-router-dom';
import SimpleBar from "simplebar-react";
import { RiEdit2Line,  RiCommunityLine, RiUser3Line, RiBankCardLine } from 'react-icons/ri';
import ListSidebar from "../../parts/ListSidebar";
import Table from "../../parts/Table";
import moment from "moment";
import ApplicationService from "../../services/ApplicationService";
import {useTranslation} from "react-i18next";
import ApplicantDelete from "../../parts/ApplicantDelete";


const MemberList = () => {
    const t = useTranslation().t;
    const [state, setState] = useState({
        view: "approved",
        applications: []
    });

    const changeView = (view) => {
        setState({ ...state, view: view });
    };

    const tableHeaders = [t('lbl_company'), t('lbl_person_contact'), t('lbl_status'), t('lbl_actions')];

    useEffect(() => {
        fetch()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetch = async () =>{
        try {
            const response = await ApplicationService.getApplications();
            setState({ ...state, applications: response.data });
        } catch (err) {
            console.error("Error while getting application list:", err)
        }
    }
    return (
        <Fragment>
            <div className='d-lg-flex row'>
                <ListSidebar title={t('txt_members')}>
                    <SimpleBar>
                        <ul className="list-unstyled file-manager-menu">
                            <li>
                                <span className={`${state.view === "approved" ? 'active' : ''}`} onClick={() => changeView("approved")}>
                                    {t('lbl_approved')}
                                </span>
                            </li>
                            <li style={{display: 'none'}}>
                                <span className={`${state.view === "open" ? 'active' : ''}`} onClick={() => changeView("open")}>
                                    {t('lbl_open')}
                                </span>
                            </li>
                            <li style={{display: 'none'}}>
                                <span className={`${state.view === "pending" ? 'active' : ''}`} onClick={() => changeView("pending")}>
                                    {t('lbl_pending')}
                                </span>
                            </li>
                            <li style={{display: 'none'}}>
                                <span className={`${state.view === "rejected" ? 'active' : ''}`} onClick={() => changeView("rejected")}>
                                    {t('lbl_rejected')}
                                </span>
                            </li>
                            <li>
                                <span className={`${state.view === "canceled" ? 'active' : ''}`} onClick={() => changeView("canceled")}>
                                    {t('lbl_canceled')}
                                </span>
                            </li>
                            <li>
                                <span className={`${state.view === "suspended" ? 'active' : ''}`} onClick={() => changeView("suspended")}>
                                    {t('lbl_suspended')}
                                </span>
                            </li>
                        </ul>
                    </SimpleBar>
                </ListSidebar>
                <div className='col-md-10'>
                    <div className={'card'}>
                        <div style={{padding: "24px"}} className={'card-body'}>
                            <div className="d-flex align-items-center mb-3">
                                <h5 style={{textTransform: "capitalize"}} className="flex-grow-1 fs-16 mb-0" id="filetype-title">
                                    {t(`lbl_${state.view}`)}
                                </h5>
                            </div>
                            <Table headers={tableHeaders}>
                                {state.applications.filter(application => state.view === application.status).map(application => (
                                    <tr style={{display: `${state.view === application.status ? 'table-row' : 'none'}`}} key={`${application._id}`}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 fs-17 me-2 filelist-icon text-success">
                                                    <RiCommunityLine />
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1 filelist-name">{application.company ? application.company.companyName : t('lbl_no_company')}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 fs-17 me-2 filelist-icon text-warning">
                                                    <RiUser3Line />
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1 filelist-name">{application.person.firstName} {application.person.lastName}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {application.rejectedUntil ? (
                                                <div>
                                                    {t('lbl_member_rejected_until')} {application.rejectedUntil && moment(application.rejectedUntil).format('DD.MM.YYYY')}
                                                </div>
                                            ) : (
                                                <div>
                                                    {t('lbl_member_since')} {application.approvedDate && moment(application.approvedDate).format('DD.MM.YYYY')} {t('lbl_until')} {application.validUntil && moment(application.validUntil).format('DD.MM.YYYY')} {application.status === 'canceled' ? `(${t('lbl_canceled')})` : ''} {application.status === 'suspended' ? `(${t('lbl_suspended')})` : ''}
                                                </div>
                                            )}</td>
                                        <td>
                                            <div className="d-flex gap-3 justify-content-center">
                                                <Link to={`/application/${application._id}`}>
                                                    <button type="button" className="btn btn-ghost-primary btn-icon btn-sm dcv-tooltip">
                                                        <RiEdit2Line />
                                                        <span className="tooltiptext">Bearbeiten</span>
                                                    </button>
                                                </Link>
                                                <Link to={`/member-card/${application.user._id}`}>
                                                    <button type="button" className="btn btn-ghost-primary btn-icon btn-sm dcv-tooltip">
                                                        <RiBankCardLine />
                                                        <span className="tooltiptext dcv-width120">Mitglieder Ausweis</span>
                                                    </button>
                                                </Link>
                                                <ApplicantDelete id={application._id} fetch={fetch} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {state.applications.filter(application => application.status === "open").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "open" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_open_requests')}</td></tr>
                                )}
                                {state.applications.filter(application => application.status === "approved").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "approved" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_approved_requests')}</td></tr>
                                )}
                                {state.applications.filter(application => application.status === "rejected").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "rejected" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_rejected_requests')}</td></tr>
                                )}
                                {state.applications.filter(application => application.status === "pending").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "pending" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_pending_requests')}</td></tr>
                                )}
                                {state.applications.filter(application => application.status === "canceled").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "canceled" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_canceled_requests')}</td></tr>
                                )}
                                {state.applications.filter(application => application.status === "suspended").length === 0 && (
                                    <tr className="text-center" style={{display: `${state.view === "suspended" ? 'table-row' : 'none'}`}}><td colSpan={9}>{t('lbl_no_suspended_requests')}</td></tr>
                                )}

                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MemberList;
