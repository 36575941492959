import React from 'react';

const ListSidebar = ({title='', children}) => {
    return (
        <div className='col-md-2'>
            <div className='p-3 d-flex flex-column h-100 card'>
                <div className='mb-3'>
                    <h5 className="mb-0 fw-semibold">{title}</h5>
                </div>
                <div className='mt-3 mx-n4 px-4 file-menu-sidebar-scroll'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ListSidebar;
